<script setup lang="ts">

  let showtotop = ref(false)

  function handleScroll() {
    showtotop.value = true;
  }

  function totop() {
    window.scrollTo(0, 0);
  }

  if (process.client) {
    window.addEventListener('scroll', handleScroll);
  }

</script>

<template>
  <div
    v-show="showtotop"
    class="w-full xs:hidden sm:hidden fixed z-30 left-0 bottom-0 border-b-4 border-navbgsecondary-dark"
  >
    <div class="container text-center mx-auto">
      <button
        type="button"
        aria-label="Back to top of the page"
        class="w-16 h-6 text-center leading-tight rounded-t-md shadow-lg bg-navbgsecondary-dark outline-none focus:outline-none opacity-75 hover:opacity-100 transition-opacity duration-500"
        @click="totop()"
      >
        <slot class="ml-1">
          <font-awesome-icon :icon="['fas', 'arrow-circle-up']" />
        </slot>
      </button>
    </div>
  </div>
</template>
